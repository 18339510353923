"use client"

import { SendbirdWrapper } from "chat"
import type { Session } from "next-auth"
import { usePathname } from "next/navigation"
import React from "react"

import Footer from "@/components/footer/Footer"
import HeaderNavbar from "@/components/header-navbar/HeaderNavbar"
import BendaharaHeader from "@/components/header/BendaharaHeader"
import Header from "@/components/header/Header"

import { SENBIRD_APP_ID } from "@/config/client"
import FeatureFlagsProvider from "@/contexts/FeatureFlags"
import { USER_ROLE } from "@/types/type"
import checkIsBendahara from "@/utils/checkIsBendahara"
import { type FeatureFlag } from "@/utils/getFlag"
import LoadingAuth from "../auth/components/LoadingAuth"

interface IClientProvidersProps {
  children: React.ReactNode
  session: Session | null
  cartCount: number
  readonly featureFlags: FeatureFlag
}

export default function ClientProviders(props: IClientProvidersProps) {
  const isOnboarding = props.session?.user?.isOnboarding
  const pathname = usePathname()

  if (isOnboarding === false && props.session?.user?.role === USER_ROLE.PP) {
    return <LoadingAuth />
  } else {
    return (
      <div className="flex h-screen flex-col justify-between gap-12">
        <FeatureFlagsProvider value={props.featureFlags}>
          <HeaderNavbar
            session={props.session}
            featureFlags={props.featureFlags}
          />
        </FeatureFlagsProvider>
        {(checkIsBendahara(props.session?.user?.role) && (
          <BendaharaHeader session={props.session} />
        )) ||
          (pathname !== "/chat" && (
            <FeatureFlagsProvider value={props.featureFlags}>
              <Header session={props.session} cartCount={props.cartCount} />
            </FeatureFlagsProvider>
          )) || (
            <SendbirdWrapper
              appId={SENBIRD_APP_ID}
              userId={props?.session?.user?.id ?? ""}
              name={props?.session?.user?.name ?? ""}
            >
              <Header session={props.session} cartCount={props.cartCount} />
            </SendbirdWrapper>
          )}

        <div className="pt-[110px]">{props.children}</div>
        <Footer />
      </div>
    )
  }
}

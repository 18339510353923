const ROUTE = {
  cart: () => "/cart",
  chat: () => "/chat",
  checkout: () => "/checkout",
  notification: () => "/notification",
  product: (username: string, slug: string) => `/${username}/${slug}`,
  sortingProducts: (keyword: string, sortBy: string) =>
    `/search?keyword=${keyword}&sortBy=${sortBy}`,
  reportProduct: () => "/",
}

export default ROUTE

import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"

export type TLastSeen = {
  imgUrl: string
  sellerName: string
  productSlug: string
}

type TSearchHistoryStore = {
  lastQuest: string[]
  setLastQuest: (data: string) => void
  lastSeen: TLastSeen[]
  setLastSeen: (data: TLastSeen) => void
  resetLastQuest: () => void
  resetLastSeen: () => void
}

export const useSearchHistoryStore = create<TSearchHistoryStore>()(
  devtools(
    persist(
      (set) => ({
        lastQuest: [],
        setLastQuest: (data: string) =>
          set((state) => {
            const tempLastQuest = [...state.lastQuest]
            if (tempLastQuest.includes(data)) {
              tempLastQuest.splice(tempLastQuest.indexOf(data), 1)
            } else {
              if (tempLastQuest.length >= 6) {
                tempLastQuest.pop()
              }
            }
            tempLastQuest.unshift(data)
            return {
              lastQuest: tempLastQuest,
            }
          }),
        lastSeen: [],
        setLastSeen: (data: TLastSeen) =>
          set((state) => {
            const tempLastSeen = [...state.lastSeen]
            const lastSeenIdx = tempLastSeen.findIndex(
              (item) =>
                item.sellerName === data.sellerName &&
                item.productSlug === data.productSlug
            )
            if (lastSeenIdx >= 0) {
              tempLastSeen.splice(lastSeenIdx, 1)
            } else {
              if (tempLastSeen.length >= 6) {
                tempLastSeen.pop()
              }
            }
            tempLastSeen.unshift(data)
            return {
              lastSeen: tempLastSeen,
            }
          }),
        resetLastQuest: () => set(() => ({ lastQuest: [] })),
        resetLastSeen: () => set(() => ({ lastSeen: [] })),
      }),
      {
        name: "search-history-storage",
      }
    )
  )
)

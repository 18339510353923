import debounce from "lodash.debounce"
import Image from "next/image"
import { useRouter, usePathname, useSearchParams } from "next/navigation"
import { useEffect, useState, useMemo } from "react"
import { Spinner, Divider } from "shared-ui"
import { getUserInfo } from "shared-utils/authentication"

import SearchBar from "@/components/search-bar"
import SearchSellerResult from "./SearchSellerResult"

import { useSearchHistoryStore } from "@/store/useSearchHistoryStore"
import useGetAutoCompleteProduct from "../hooks/useGetAutoCompleteProduct"
import useGetSearchSeller from "@/app/(navigation-layout)/search/hooks/useGetSearchSeller"

type TSearchBarSuggestionProps = {
  searchSuggestions?: []
  lastQuests?: []
  lastSeens?: []
}

const SearchBarSuggestion = ({}: TSearchBarSuggestionProps) => {
  const keywordQueryString = useSearchParams()?.get("keyword") ?? ""
  const router = useRouter()
  const pathname = usePathname()
  const [keyword, setKeyword] = useState<string>(keywordQueryString)
  const [isSearchBarOnFocus, setIsSearchBarOnFocus] = useState<boolean>(false)
  const [isAutoCompleteEnabled, setIsAutoCompleteEnabled] =
    useState<boolean>(false)

  const session = getUserInfo()
  const isLoggedIn = session?.user?.id

  const { lastQuest, lastSeen, setLastQuest, resetLastQuest, resetLastSeen } =
    useSearchHistoryStore()

  const saveLastQuest = (val: string) => {
    if (!isLoggedIn) {
      return
    }
    setLastQuest(val)
  }

  const handleAfterSearch = () => {
    const element = document.getElementById("search-bar-input")
    element?.blur()
    setIsAutoCompleteEnabled(false)
    setIsSearchBarOnFocus(false)
  }

  const handleSearchKeyword = (val: string) => {
    saveLastQuest(val)
    router.push(`/search?keyword=${val}&page=1`)
    setKeyword(val)

    handleAfterSearch()
  }

  const goToProduct = (username: string, productSlug: string) => {
    router.push(`/${username}/${productSlug}`)
  }

  const handleSearchBlur = () => {
    setTimeout(() => {
      setIsSearchBarOnFocus(false)
    }, 300)
  }

  const {
    data: autoCompleteResult,
    isFetching: isAutoCompleteFetching,
    isFetched: isFetchedSearchProduct,
  } = useGetAutoCompleteProduct({
    variables: {
      keyword,
    },
    enabled: isAutoCompleteEnabled,
  })

  const inputSearchSeller = {
    filter: { keyword },
    pagination: { page: 1, perPage: 5 },
  }

  const {
    data: searchSellerResult,
    isFetching: isFetchingSearchSeller,
    isFetched: isFetchedSearchSeller,
  } = useGetSearchSeller({
    variables: {
      input: inputSearchSeller,
    },
    enabled: !!keyword,
  })

  const isEmptySearchResult =
    !autoCompleteResult?.items?.length &&
    !searchSellerResult?.items?.length &&
    isFetchedSearchProduct &&
    isFetchedSearchSeller

  const debounceSearchKeywordAutocompletion = useMemo(
    () => debounce(() => setIsAutoCompleteEnabled(true), 500),
    []
  )

  const handleChange = (val: string) => {
    setKeyword(val)
    debounceSearchKeywordAutocompletion()
  }

  useEffect(() => {
    if (pathname !== "/search") {
      setKeyword("")
    }
  }, [pathname])

  useEffect(() => {
    if (autoCompleteResult && !!autoCompleteResult.items.length) {
      setIsAutoCompleteEnabled(false)
    }
  }, [autoCompleteResult])

  return (
    <div className="relative">
      <SearchBar
        value={keyword}
        onChange={handleChange}
        placeholder="Cari produk & penyedia di sini..."
        size={20}
        onClear={setKeyword}
        onEnter={handleSearchKeyword}
        onFocus={() => setIsSearchBarOnFocus(true)}
        onBlur={handleSearchBlur}
        maxLength={100}
      />

      {isSearchBarOnFocus && (
        <>
          <div
            className="fixed left-0 top-[108px] z-10 h-full w-full bg-tertiary500 opacity-60"
            onClick={() => setKeyword("")}
          />

          <div className="absolute z-10 mt-[18px] flex h-auto max-h-[65vh] w-full flex-col overflow-y-auto rounded-b-4 border-tertiary50 bg-primary25 p-0 text-tertiary500">
            {isAutoCompleteFetching && (
              <div className="flex items-center justify-center p-4">
                <Spinner size="small" color="primary" />
              </div>
            )}

            {isEmptySearchResult && (
              <div className="text-caption-lg-regular flex justify-center px-4 pb-4 text-tertiary300">
                Produk atau penyedia tidak ditemukan
              </div>
            )}

            {!!autoCompleteResult?.items?.length && (
              <div className="p-4 pb-0">
                <div className="flex flex-col gap-3">
                  {autoCompleteResult.items.map((item, idx) => (
                    <div
                      key={idx}
                      className="text-caption-lg-regular cursor-default cursor-pointer hover:font-semibold"
                      onClick={() => handleSearchKeyword(item.text)}
                    >
                      {item.text}
                    </div>
                  ))}
                </div>
                <Divider color="tertiary50" className="!m-0 pt-4" />
              </div>
            )}

            <SearchSellerResult
              handleAfterSearch={handleAfterSearch}
              keyword={keyword}
              clearKeyword={() => setKeyword("")}
              searchSellerResult={searchSellerResult}
              isFetchingSearchSeller={isFetchingSearchSeller}
            />

            {!!lastQuest.length && (
              <div className="p-4 pb-0">
                <div className="flex items-center justify-between">
                  <p className="text-body-sm-semibold">Pencarian Terakhir</p>
                  <button
                    className="text-caption-lg-semibold text-secondary500"
                    id="delete-last-quest-search-history"
                    onClick={() => resetLastQuest()}
                  >
                    Hapus Semua
                  </button>
                </div>
                <div className="mt-4 flex flex-col gap-2">
                  {lastQuest.map((item, idx) => (
                    <div
                      key={idx}
                      className="text-caption-lg-regular cursor-default cursor-pointer hover:font-semibold"
                      onClick={() => handleSearchKeyword(item)}
                    >
                      {item}
                    </div>
                  ))}
                </div>

                <Divider color="tertiary50" className="!m-0 pt-4" />
              </div>
            )}

            {!!lastSeen.length && (
              <div className="p-4">
                <div className="flex items-center justify-between">
                  <p className="text-body-sm-semibold">Terakhir Dilihat</p>
                  <button
                    className="text-caption-lg-semibold text-secondary500"
                    id="delete-last-seen-search-history"
                    onClick={() => resetLastSeen()}
                  >
                    Hapus Semua
                  </button>
                </div>
                <div className="mt-4 flex flex-wrap gap-3">
                  {lastSeen.map((item, idx) => (
                    <Image
                      className="h-[102px] w-[102px] cursor-pointer rounded-2 object-cover"
                      alt={`image-product-${item.productSlug}`}
                      src={item.imgUrl}
                      key={idx}
                      width={102}
                      height={102}
                      onClick={() =>
                        goToProduct(item.sellerName, item.productSlug)
                      }
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default SearchBarSuggestion

import { type KeyboardEvent, type ChangeEventHandler } from "react"
import { Search, X as XIcon } from "react-feather"

type searchType = {
  onChange: (e: string) => void
  placeholder: string
  value: string
  size?: number
  onClear?: (e: string) => void
  onEnter?: (e: string) => void
  onFocus?: () => void
  onBlur?: () => void
  maxLength?: number
}

const SearchBar = ({
  onChange,
  placeholder,
  value,
  size,
  onClear,
  onEnter,
  onFocus,
  onBlur,
  maxLength,
}: searchType) => {
  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    event.preventDefault()
    onChange(event.target.value)
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (!value) {
      return
    }

    if (event.key === "Enter") {
      onEnter && onEnter(value)
    }
  }
  return (
    <div
      className="flex w-full flex-row gap-3 rounded-4 border border-solid border-tertiary100 px-3 py-2 text-sm 
    text-tertiary500 focus-within:border-tertiary200 focus-within:ring-2 focus-within:ring-grey50"
    >
      <Search size={size} className="max-w-none" />
      <input
        id="search-bar-input"
        className="flex-grow outline-none focus:border-none"
        placeholder={placeholder}
        value={value}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
        autoComplete="off"
        maxLength={maxLength}
      />

      {onClear && value && (
        <XIcon
          size={20}
          className="max-w-none cursor-pointer"
          onClick={() => onClear("")}
        />
      )}
    </div>
  )
}

export default SearchBar

import { createQuery } from "react-query-kit"
import { type GenericExtractor } from "shared-utils"

import { GET_SEARCH_SELLER } from "../../federated/queries"
import {
  type GetSearchSellerQueryVariables,
  type GetSearchSellerQuery,
} from "@/federatedGql/graphql"
import { federatedGqlClient } from "@/utils/graphqlClient"

type TUseGetSearchSellerResponse = GenericExtractor<
  GetSearchSellerQuery["searchSeller"]
>

const useGetSearchSeller = createQuery<
  TUseGetSearchSellerResponse,
  GetSearchSellerQueryVariables
>({
  primaryKey: "getSearchSeller",
  queryFn: async ({ queryKey: [, variables] }) => {
    const result = await federatedGqlClient.request(
      GET_SEARCH_SELLER,
      variables
    )
    if (result?.searchSeller.__typename === "GenericError") {
      throw Error(result.searchSeller.message)
    }
    return result.searchSeller
  },
})

export default useGetSearchSeller

import { createQuery } from "react-query-kit"
import { type GenericExtractor } from "shared-utils"

import { GET_AUTO_COMPLETE_PRODUCT } from "../federated/queries"
import {
  type GetAutoCompleteProductQueryVariables,
  type GetAutoCompleteProductQuery,
} from "@/federatedGql/graphql"
import { federatedGqlClient } from "@/utils/graphqlClient"

type TUseGetAutoCompleteProductResponse = GenericExtractor<
  GetAutoCompleteProductQuery["autoCompleteProduct"]
>

const useGetAutoCompleteProduct = createQuery<
  TUseGetAutoCompleteProductResponse,
  GetAutoCompleteProductQueryVariables
>({
  primaryKey: "getSearchSeller",
  queryFn: async ({ queryKey: [, variables] }) => {
    const result = await federatedGqlClient.request(
      GET_AUTO_COMPLETE_PRODUCT,
      variables
    )
    if (result?.autoCompleteProduct.__typename === "GenericError") {
      throw Error(result.autoCompleteProduct.message)
    }
    return result.autoCompleteProduct
  },
})

export default useGetAutoCompleteProduct

import { Divider, Spinner } from "shared-ui"
import { useRouter } from "next/navigation"
import Image from "next/image"
import capitalize from "@/utils/capitalize"
import EmptyStoreIllus from "shared-assets/images/empty-store.png"
import { SearchSellerResponse } from "@/federatedGql/graphql"

type TSearchSellerResultProps = {
  handleAfterSearch: () => void
  keyword: string
  clearKeyword: () => void
  searchSellerResult?: SearchSellerResponse
  isFetchingSearchSeller: boolean
}

const SearchSellerResult = ({
  handleAfterSearch,
  keyword,
  clearKeyword,
  searchSellerResult,
  isFetchingSearchSeller,
}: TSearchSellerResultProps) => {
  const router = useRouter()

  const handleSearchKeywordSeller = (slug: string) => {
    router.push(`/${slug}`)
    handleAfterSearch()
  }

  const goToSellerPage = () => {
    clearKeyword()
    router.push(`/search?tabActive=SEARCH_SELLER&keyword=${keyword}`)
  }

  return (
    <div className="pb-0">
      {isFetchingSearchSeller && (
        <div className="flex items-center justify-center p-4">
          <Spinner size="small" color="primary" />
        </div>
      )}
      {searchSellerResult?.items?.length && (
        <div className="p-4">
          <div className="mb-4 flex items-center justify-between">
            <p className="text-body-sm-semibold">Penyedia</p>
            <button
              className="text-caption-lg-semibold text-secondary500"
              id="delete-last-quest-search-history"
              onClick={goToSellerPage}
            >
              Lihat Semua Penyedia
            </button>
          </div>
          <div className="flex flex-col gap-2">
            {searchSellerResult.items.map((item, idx) => (
              <div
                key={idx}
                className="text-caption-lg-regular flex cursor-pointer gap-3 rounded-2 px-3 py-2 hover:bg-tertiary25 hover:font-semibold"
                onClick={() => handleSearchKeywordSeller(item.slug)}
              >
                <div className="min-w-[42px]">
                  <Image
                    src={item.imageUrl || EmptyStoreIllus}
                    alt={item.name}
                    width={42}
                    height={42}
                    className="rounded-full"
                  />
                </div>
                <div>
                  <div className="text-caption-lg-bold">{item.name}</div>
                  <div className="text-caption-lg-regular">
                    {capitalize(item.location.child.name || "")}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Divider color="tertiary50" className="!m-0 pt-4" />
        </div>
      )}
    </div>
  )
}

export default SearchSellerResult

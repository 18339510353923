import { createQuery } from "react-query-kit"
import { type GenericExtractor } from "shared-utils"

import { GET_UNREAD_CHAT_COUNT } from "../federated/queries"
import type {
  UnreadChatCountQueryVariables,
  UnreadChatCountResp,
  UnreadChatCountResponse,
} from "@/federatedGql/graphql"
import { federatedGqlClient } from "@/utils/graphqlClient"

export const useGetUnreadChatCount = createQuery<
  GenericExtractor<UnreadChatCountResponse> | undefined,
  UnreadChatCountQueryVariables
>({
  primaryKey: "useGetUnreadChatCount",
  queryFn: async () => {
    const res = await federatedGqlClient.request(GET_UNREAD_CHAT_COUNT)
    if (res.unreadChatCount.__typename === "GenericError") {
      throw new Error(res.unreadChatCount.message || "Error")
    }
    return res.unreadChatCount as UnreadChatCountResp
  },
  refetchOnMount: true,
})

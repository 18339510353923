import { create } from "zustand"
import { devtools } from "zustand/middleware"

interface INotificationBadge {
  chatNotification: number
  bellNotification: number
  cartBadgeTotal: number
  notificationModalOpen: boolean
  setNotificationModalOpen: (isOpen: boolean) => void
  setChatNotification: (chat: number) => void
  setBellNotification: (bell: number) => void
  setCartNotification: (cart: number) => void
}

export const useNotificationBadgeStore = create<INotificationBadge>()(
  devtools(
    (set) => ({
      chatNotification: 0,
      bellNotification: 0,
      cartBadgeTotal: 0,
      notificationModalOpen: false,
      setNotificationModalOpen: (isOpen) =>
        set(() => ({ notificationModalOpen: isOpen })),
      setChatNotification: (chat) => set(() => ({ chatNotification: chat })),
      setBellNotification: (bell) => set(() => ({ bellNotification: bell })),
      setCartNotification: (cart) => set(() => ({ cartBadgeTotal: cart })),
    }),
    {
      name: "chat-notification-storage",
    }
  )
)

export const isValidUrl = (url?: string) => {
  try {
    const result = url ? Boolean(new URL(url)) : true
    return result
  } catch (e) {
    return false
  }
}

export default isValidUrl

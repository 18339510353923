import { useChannelListContext } from "@sendbird/uikit-react/ChannelList/context"
import { useChatNotification } from "chat"
import { usePathname } from "next/navigation"
import React from "react"

import IconNav from "./IconNav"
import { useGetUnreadChatCount } from "../hooks/useGetUnreadChatCount"
import ROUTE from "@/config/route"

const ChatIconNav = () => {
  const { allChannels } = useChannelListContext()
  const chatNotification: number = useChatNotification(allChannels)
  const { data } = useGetUnreadChatCount()
  const pathname = usePathname()

  return (
    <IconNav
      nameIcon="Mail"
      countNotification={
        pathname !== "/chat" ? data?.count ?? 0 : chatNotification
      }
      pathname={ROUTE.chat()}
    />
  )
}

export default ChatIconNav

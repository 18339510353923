import { Spinner } from "shared-ui"

const LoadingAuth = () => {
  return (
    <div className="grid h-screen w-full items-center justify-center">
      <div className="flex flex-col items-center justify-center">
        <Spinner size="large" color="primary" className="!m-auto block" />
        <div className="mt-5 text-primary600">Please wait...</div>
      </div>
    </div>
  )
}

export default LoadingAuth

import { useQuery } from "@tanstack/react-query"

import { GET_ALL_MINIFIED_CATEGORY } from "@/app/(navigation-layout)/federated/queries"
import { type GetAllMinifiedCategoryQueryVariables } from "@/federatedGql/graphql"
import { federatedGqlClient } from "@/utils/graphqlClient"

type TUseGetMinifedCategory = {
  enabled: boolean
  variables: GetAllMinifiedCategoryQueryVariables
}

const useGetMinifedCategory = ({
  enabled,
  variables,
}: TUseGetMinifedCategory) => {
  const query = useQuery({
    queryKey: ["getAllMinifiedCategory", variables],
    queryFn: async () => {
      const result = await federatedGqlClient.request(
        GET_ALL_MINIFIED_CATEGORY,
        variables
      )
      if (result?.allMinifiedProductCategory?.__typename === "GenericError") {
        throw new Error(result.allMinifiedProductCategory.message)
      }
      return result.allMinifiedProductCategory.items
    },
    enabled: enabled,
    cacheTime: 150000, // 15 Minutes
    staleTime: 50000, // 5 Minutes
  })

  return query
}

export default useGetMinifedCategory

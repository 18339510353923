import { type SetStateAction, type Dispatch } from "react"
import { SimpleTabBar, SimpleTabBarItem } from "shared-ui"

import { ProductType } from "@/federatedGql/graphql"

type TCategoryTypeTabs = {
  activeTab: ProductType
  setActiveTab: Dispatch<SetStateAction<ProductType>>
}

const productTypeOptions = [
  { label: "Barang", value: ProductType.Physical },
  { label: "Digital", value: ProductType.Digital },
  { label: "Jasa", value: ProductType.Service },
]

const CategoryTypeTabs = ({ activeTab, setActiveTab }: TCategoryTypeTabs) => {
  return (
    <div className="-mt-2">
      <SimpleTabBar customClassNames="!mb-2">
        {productTypeOptions.map((item, index) => (
          <SimpleTabBarItem
            key={index}
            id={activeTab === item.value ? "tabItemActive" : "tabItem"}
            label={item.label}
            isActive={activeTab === item.value}
            onClick={() => setActiveTab(item.value)}
          />
        ))}
      </SimpleTabBar>
    </div>
  )
}

export default CategoryTypeTabs

import { cx } from 'class-variance-authority'
import { type PropsWithChildren } from 'react'

import styles from './SimpleTabBar.module.css'

export interface SimpleTabBarProps extends PropsWithChildren {
  customClassNames?: string
}

export function SimpleTabBar({ children, customClassNames }: SimpleTabBarProps) {
  return <nav className={cx(styles.simpleTabs, customClassNames)}>{children}</nav>
}

export interface SimpleTabBarItemProps extends PropsWithChildren {
  id: string
  onClick: (tabId: string) => void
  tabName?: string
  label?: string
  isActive?: boolean
  customClassNames?: string
}

export function SimpleTabBarItem({ id, label, isActive = false, onClick, customClassNames }: SimpleTabBarItemProps) {
  return (
    <div
      className={cx(styles.tab, isActive && styles.active, customClassNames)}
      id={id}
      onClick={() => onClick && onClick(id)}
      tabIndex={0}
    >
      {label}
    </div>
  )
}

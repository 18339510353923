import Link from "next/link"
import { type Session } from "next-auth"
import { useState, type MouseEvent } from "react"
import { ChevronUp, ChevronDown, Settings, LogOut } from "react-feather"
import { Dropdown, Avatar } from "shared-ui"

import AccountInfo from "./AccountInfo"
import { signOut } from "@/authentication/authService"
import { useSelectedBuyerAddressStore } from "@/store/useSelectedBuyerAddressStore"
import { getAccountPpnUrl } from "@/utils/common"

interface IUserDropdownProps {
  session: Session
}

const UserDropdown = ({ session }: IUserDropdownProps) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false)

  const toggleDropdown = () => setShowDropdown((prev) => !prev)
  const onClose = () => setShowDropdown(false)

  const { resetSelectedBuyerAddress } = useSelectedBuyerAddressStore()

  const handleSignout = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    resetSelectedBuyerAddress()
    signOut()
  }

  return (
    <Dropdown
      data-test="header-dropdown"
      onOpenChange={toggleDropdown}
      open={showDropdown}
      trigger={
        <div
          className="mt-1 flex h-12 w-22 cursor-pointer items-center"
          data-test="header-userInfoSection"
        >
          <div className="w-10">
            <Avatar
              online={false}
              size="md"
              src={{ text: session?.user?.fullName || "" }}
            />
          </div>
          <div className="ml-4 flex w-19 flex-col">
            <h1
              data-test="header-user-fullname"
              className="truncate text-xs font-semibold capitalize sm:text-base"
            >
              {session.user?.fullName || ""}
            </h1>
            <p
              data-test="header-user-role"
              className="mt-1 text-[10px] capitalize text-tertiary300 sm:text-xs"
            >
              {session.user?.role}
            </p>
          </div>
          <div data-test="header-dropdown-button" className="w-5">
            {showDropdown ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
          </div>
        </div>
      }
    >
      <div data-test="dropdown-options" className="w-[250px] text-sm">
        <AccountInfo session={session} />

        <Link
          href={getAccountPpnUrl(session.user?.personaId).setting}
          target="_blank"
          data-test="header-profile-link"
          onClick={onClose}
          className="hover:underline"
        >
          <div className="flex items-center gap-[9px] px-5 py-3">
            <Settings size={18} />
            <p data-test="dropdown-option">Pengaturan</p>
          </div>
        </Link>

        <div
          data-test="header-logout-button"
          className="flex cursor-pointer items-center gap-[9px] px-5 py-3 hover:underline"
        >
          <div className="flex gap-2" onClick={handleSignout}>
            <LogOut size={18.33} />
            <p data-test="dropdown-option">Keluar</p>
          </div>
        </div>
      </div>
    </Dropdown>
  )
}

export default UserDropdown
